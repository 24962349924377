<template>
  <div class="about">
    <div class="row justify-content-start">
      <div class="col-12 bg">
        <div style="margin-top: 100px">
          <p class="title">隐形保镖隐形车衣报价系统</p>
          <p class="describe">请选择汽车品牌，获取产品报价信息</p>
        </div>
        <div
          style="margin: 100px 0px 0px 0px;display: flex; align-items: center; flex-direction: row; align-items: center; justify-content: center;">
          <div class="col-2">
            <select class="form-control" v-model="productType" @change="onChangeProductType">
              <option v-for="item in productTypeList" :key="item" :label="item" :value="item"></option>
            </select>
          </div>
          <div class="col-2">
            <select class="form-control" v-model="brand" @change="onChangeBrand">
              <option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.name"></option>
            </select>
          </div>
          <div class="col-2">
            <select class="form-control" v-model="model">
              <option v-for="item in carModelList" :key="item.id" :label="item.name" :value="item">
              </option>
            </select>
          </div>
          <div class="col-2" v-if="productType == '隐形车衣'">
            <select class="form-control" v-model="type" @change="onChannelChange">
              <option v-for="item in typeList" :key="item" :label="item" :value="item"></option>
            </select>
          </div>
          <div class="col-2" v-if="type == '集团PDI渠道' && productType == '隐形车衣'">
            <select class="form-control" v-model="level">
              <option v-for="item in channelGroupList" :key="item" :label="item.productLevelName"
                :value="item.productLevelName"></option>
            </select>
          </div>
          <div class="col-2" v-if="type == '零售渠道' && productType == '隐形车衣'">
            <select class="form-control" v-model="level">
              <option v-for="item in channelRetailList" :key="item" :label="item.productLevelName"
                :value="item.productLevelName"></option>
            </select>
          </div>
          <div class="col-2" v-if="productType == '汽车窗膜'">
            <select class="form-control" v-model="level">
              <option v-for="item in windowProductList" :key="item" :label="item.productLevelName"
                :value="item.productLevelName"></option>
            </select>
          </div>
        </div>
        <button class="button" style="margin: 80px 0px 0px 0px; width: 200px" @click="onChange">
          提交查询
        </button>
      </div>
    </div>
    <div class="row" style="padding: 30px 0px 30px 0px; background-color: #f3f3f2">
      <div class="col-2"></div>
      <div class="col-8 price">
        <header class="p-header">
          <h3 class="title">隐形保镖隐形车衣报价查询结果</h3>
        </header>
        <div class="row">
          <!-- <div class="col-6">
            <img :src="model.image" />
          </div> -->
          <div class="col-1" />
          <div class="col-10">
            <!-- <table
              class="table table-bordered table-hover"
              style="margin: 40px 10px 40px 0px; width:600px"
            > -->
            <table class="table table-bordered table-hover" style="margin: 40px 10px 40px 0px">
              <thead>
                <tr>
                  <th scope="col">部位</th>
                  <th scope="col">报价</th>
                </tr>
              </thead>
              <tbody v-if="productType == '隐形车衣' ">
                <tr v-for=" item in bodyPriceList" :key="item">
                  <td :style=" item.name == '整车' ? 'color: #ecbd4d; font-size: 16px; font-weight: bold' : '' ">
                    {{item.name}}</td>
                  <td :style=" item.name == '整车' ? 'color: #ecbd4d; font-size: 16px; font-weight: bold' : '' ">¥{{
                    item.price }}</td>
                </tr>
              </tbody>
              <tbody v-if="productType == '汽车窗膜' ">
                <tr v-for=" item in windowPriceList" :key="item">
                  <td :style=" item.name == '整车' ? 'color: #ecbd4d; font-size: 16px; font-weight: bold' : '' ">
                    {{item.name}}</td>
                  <td :style=" item.name == '整车' ? 'color: #ecbd4d; font-size: 16px; font-weight: bold' : '' ">¥{{
                    item.price }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-1"/>
        </div>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</template>
<script>
  export default {
    components: {},
    data () {
      return {
        modelList: [],
        typeList: ['集团PDI渠道', '零售渠道'],
        productTypeList: ['隐形车衣', '汽车窗膜'],
        productType: null,
        carModelList: [],
        priceList: [],
        brand: null,
        type: null,
        level: null,
        model: {},
        bodyPriceList: [{
          id: 0,
          name: "整车",
          price: 0
        }, {
          id: 1,
          name: "车顶盖",
          price: 0
        }, {
          id: 2,
          name: "引擎盖",
          price: 0
        }, {
          id: 3,
          name: "前保险杠",
          price: 0
        }, {
          id: 4,
          name: "后保险杠",
          price: 0
        }, {
          id: 5,
          name: "双前叶子板",
          price: 0
        }, {
          id: 6,
          name: "双后叶子板",
          price: 0
        }, {
          id: 7,
          name: "左门组合",
          price: 0
        },
        {
          id: 8,
          name: "右门组合",
          price: 0
        },
        {
          id: 9,
          name: "左侧裙",
          price: 0
        },
        {
          id: 10,
          name: "右侧裙",
          price: 0
        },
        {
          id: 11,
          name: "双后视镜",
          price: 0
        },
        {
          id: 12,
          name: "后备箱盖",
          price: 0
        }
        ],
        windowPriceList: [{
          id: 0,
          name: "整车",
          status: false,
          price: 0
        }, {
          id: 1,
          name: "前挡",
          status: false,
          price: 0
        }, {
          id: 2,
          name: "左车玻璃组",
          status: false,
          price: 0
        }, {
          id: 3,
          name: "右车玻璃组",
          status: false,
          price: 0
        }, {
          id: 4,
          name: "天窗",
          status: false,
          price: 0
        }, {
          id: 5,
          name: "后挡",
          status: false,
          price: 0
        },],
      }
    },
    computed: {
      brandList () {
        return this.$store.state.dict.brandList;
      },
      channelGroupList () {
        return this.$store.state.dict.channelGroupList;
      },
      channelRetailList () {
        return this.$store.state.dict.channelRetailList;
      },
      bodyProductList () {
        return this.$store.state.dict.bodyProductList;
      },
      windowProductList () {
        return this.$store.state.dict.windowProductList;
      }

    },
    methods: {
      onChangeProductType () {
        console.log(this.productType);
        if (this.productType == '汽车窗膜') {
          this.level = this.windowProductList[0].productLevelName;
        }
      },
      onChangeBrand () {
        console.log(this.brand);
        this.carModelList = this.brandList.find((e) => e.name == this.brand).carModelList;
        console.log(this.carModelList[0].name)
        this.model = this.carModelList[0]
        console.log(this.model)
      },
      onChange () {
        if (this.productType == '隐形车衣') {
          let item = this.bodyProductList.find(e => e.productLevelName == this.level)
          let price = item.carFilmPriceList.find(e => e.carLevelNo == this.model.levelNo)
          this.bodyPriceList.forEach(e => {
            for (var i = 1; i <= 12; i++) {
              if (e.name == price["priceKey" + i]) {
                e.price = price["priceValue" + i]
              }
              if (e.name == "整车") {
                e.price = price.price0
              }
            }
          })
          console.log(this.bodyPriceList)
        } else {
          let item = this.windowProductList.find(e => e.productLevelName == this.level)
          let price = item.carFilmPriceList.find(e => e.carLevelNo == this.model.levelNo)
          this.windowPriceList.forEach(e => {
            for (var i = 1; i <= 5; i++) {
              if (e.name == price["priceKey" + i]) {
                e.price = price["priceValue" + i]
              }
              if (e.name == "整车") {
                e.price = price.price0
              }
            }
          })
        }
      },
      onChannelChange () {
        console.log()
        if (this.type == '集团PDI渠道') {
          this.level = this.channelGroupList[0].productLevelName
        } else {
          this.level = this.channelRetailList[0].productLevelName
        }
      },
    },
  }
</script>
<style scoped>
  .bg {
    background-image: url('https://car-1301197907.cos.ap-beijing.myqcloud.com/price-1.jpg');
    height: 500px;
  }

  .title {
    font-size: 30px;
    color: #ffffff;
  }

  .describe {
    font-size: 14px;
    color: #ffffff;
  }

  .button {
    background-color: #ecbd4d;
    border: 1px solid #ecbd4d;
    border-radius: 4px;
    padding: 10px 15px 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
  }

  .button:hover {
    background-color: #ecbd4d;
  }

  .button:active {
    background-color: #ecbd4d;
  }

  .price {
    min-height: 400px;
    background-color: #ffffff;
    padding: 0;
  }

  .p-header {
    border-radius: 5px;
    background-color: #ecbd4d;
    height: 100px;
  }

  .p-header .title {
    color: #ffffff;
    padding: 30px 0 0 0;
  }
</style>